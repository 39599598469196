import { memo } from 'react'

const Failure = () => {
  return (
    <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.5 16C34.5 24.8366 27.3366 32 18.5 32C17.4129 32 16.3511 31.8916 15.3249 31.685L8.93664 36L9.85897 29.4683C5.43229 26.6223 2.5 21.6536 2.5 16C2.5 7.16344 9.66344 0 18.5 0C27.3366 0 34.5 7.16344 34.5 16Z"
        fill="white"
      />
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="11" y="8" width="15" height="15">
        <path
          d="M19.7511 15.4978L25.4741 9.7792C25.82 9.4333 25.82 8.87402 25.4741 8.53037C25.1282 8.18447 24.5689 8.18447 24.2253 8.53037L18.5 14.2467L12.7747 8.52812C12.4288 8.18223 11.8695 8.18223 11.5259 8.52812C11.18 8.87402 11.18 9.4333 11.5259 9.77695L17.2489 15.4955L11.5259 21.2163C11.18 21.5622 11.18 22.1215 11.5259 22.4651C11.6988 22.6381 11.9257 22.7234 12.1503 22.7234C12.3772 22.7234 12.6018 22.6381 12.7747 22.4651L18.5 16.7466L24.2253 22.4674C24.3982 22.6403 24.6229 22.7257 24.8497 22.7257C25.0766 22.7257 25.3012 22.6403 25.4741 22.4674C25.82 22.1215 25.82 21.5622 25.4741 21.2186L19.7511 15.4978Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5833 73.9583C48.3397 73.9583 74.0833 48.2147 74.0833 16.4583C74.0833 -15.298 48.3397 -41.0417 16.5833 -41.0417C-15.173 -41.0417 -40.9167 -15.298 -40.9167 16.4583C-40.9167 48.2147 -15.173 73.9583 16.5833 73.9583Z"
          fill="black"
          fillOpacity="0.87"
        />
      </g>
    </svg>
  )
}

export default memo(Failure)
