import { ButtonHTMLAttributes, DetailedHTMLProps, memo, MouseEvent, ReactNode, useEffect, useState } from 'react'
import Cross from 'icons/Cross'
import { requestTimeout } from 'shared/utils'
import { useTranslation } from 'react-i18next'
import { ITimer } from 'types'

interface GeneralDialogProps {
  id?: string
  open: boolean
  children?: ReactNode
  primaryActionText?: string | ReactNode
  secondaryActionText?: string | ReactNode
  onClickPrimary?: () => void
  onClickSecondary?: () => void
  hidePrimaryAction?: boolean
  hideSecondaryAction?: boolean
  showCrossClose?: boolean
  onClose: () => void
  backdropClickable?: boolean
  primaryActionProps?: DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
  secondaryActionProps?: DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
}

const GeneralDialog = (props: GeneralDialogProps) => {
  const {
    id,
    open,
    children,
    showCrossClose,
    onClose,
    backdropClickable,

    primaryActionText,
    onClickPrimary,
    hidePrimaryAction,
    primaryActionProps,

    secondaryActionText,
    onClickSecondary,
    hideSecondaryAction,
    secondaryActionProps,
  } = props
  const [visible, setVisible] = useState(open)
  const { t } = useTranslation()

  useEffect(() => {
    let timer: ITimer
    // delay 200ms for hideout transition to work
    if (!open) timer = requestTimeout(() => setVisible(false), 200)
    else setVisible(true)

    return () => timer?.clear()
  }, [open])

  const handlePrimaryClick = (ev: MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation()
    if (onClickPrimary) onClickPrimary()
    else onClose()
  }

  const handleSecondaryClick = (ev: MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation()
    if (onClickSecondary) onClickSecondary()
    else onClose()
  }

  const disableActions = hidePrimaryAction && hideSecondaryAction

  return (
    <div
      className={`absolute px-6 top-0 left-0 w-full h-full bg-black bg-opacity-50 flex flex-col items-center justify-center transition-opacity duration-200 ${
        open ? 'opacity-100' : 'opacity-0'
      } ${visible ? 'visible' : 'invisible'}`}
      id={id}
      onClick={(ev) => {
        ev.stopPropagation()
        if (backdropClickable) onClose()
      }}
    >
      <div
        onClick={(ev) => ev.stopPropagation()}
        className="bg-white rounded-[11px] w-full min-w-[290px] max-w-[470px] large:w-[470px] overflow-hidden"
        id="dialog-card"
      >
        {children}
        {!disableActions && (
          <div className="flex flex-row-reverse text-normal border-t" id="actions">
            {!hidePrimaryAction && (
              <button
                className="flex-1 py-4 text-primary text-normal large:text-title disabled:opacity-40 disabled:cursor-not-allowed"
                id="primary-action"
                onClick={handlePrimaryClick}
                {...primaryActionProps}
              >
                {primaryActionProps?.children || primaryActionText || t('Đồng ý')}
              </button>
            )}
            {!hidePrimaryAction && !hideSecondaryAction && <div className="w-px bg-gray-200" id="divider" />}
            {!hideSecondaryAction && (
              <button
                className="flex-1 py-4 text-normal large:text-title disabled:opacity-40 disabled:cursor-not-allowed"
                id="secondary-action"
                onClick={handleSecondaryClick}
                {...secondaryActionProps}
              >
                {secondaryActionProps?.children || secondaryActionText || t('Đóng')}
              </button>
            )}
          </div>
        )}
      </div>
      {showCrossClose && (
        <button
          onClick={(ev) => {
            ev.stopPropagation()
            onClose()
          }}
          id="cross-close-btn"
          className="mt-3 large:mt-6 p-3 bg-black bg-opacity-50 rounded-full"
        >
          <Cross />
        </button>
      )}
    </div>
  )
}

GeneralDialog.defaultProps = {
  id: 'general-dialog',
  hidePrimaryAction: false,
  hideSecondaryAction: false,
  showCrossClose: false,
  backdropClickable: true,
}

export default memo(GeneralDialog)
