import Failure from 'icons/Failure'
import Success from 'icons/Success'
import { memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { requestTimeout } from 'shared/utils'
import { RootState } from 'store'
import { ITimer } from 'types'

const Toast = () => {
  const { isShow, message, iconType } = useSelector((state: RootState) => state.toast)

  const [visible, setVisible] = useState(isShow)

  useEffect(() => {
    let timer: ITimer
    // delay 200ms for hideout transition to work
    if (!isShow) timer = requestTimeout(() => setVisible(false), 200)
    else setVisible(true)

    return () => timer?.clear()
  }, [isShow])

  return (
    <div
      className={`fixed bg-black bg-opacity-80 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-md p-5 w-fit-content max-w-3/4 large:max-w-[500px] min-w-[220px] flex flex-col items-center transition-opacity duration-200 ${
        isShow ? 'opacity-100' : 'opacity-0'
      } ${visible ? 'visible' : 'invisible'}`}
      id="toast"
    >
      <div className="relative mb-3">{iconType === 'success' ? <Success /> : <Failure />}</div>
      <p className="text-white text-center text-normal large:text-large font-light">{message}</p>
    </div>
  )
}

export default memo(Toast)
