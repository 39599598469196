import Image from 'next/image'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getImage } from 'shared/utils'
import { RootState } from 'store'

const ErrorScreen = ({ statusCode = 0 }: { statusCode?: number }) => {
  const error = useSelector((state: RootState) => state.app.error)
  const { t } = useTranslation()
  const router = useRouter()

  const { data, status } = error || {}
  const errorCode = data?.code || status || statusCode

  if (errorCode === 4002) {
    // TODO: handle when activity not actually running - may cause by clock of user is actually faster
  }

  if (!errorCode) return null

  const handleRetry = () => {
    router.reload()
  }

  return (
    <div className="flex flex-col justify-center text-center absolute top-0 left-0 bg-white w-full h-screen text-normal large:text-xl">
      <div className="relative mb-6 large:mb-10 mx-auto w-[200px] h-[200px] large:w-[250px] large:h-[250px]">
        <Image src={getImage('network-error.png')} alt="" draggable={false} layout="fill" />
      </div>
      <p className="">{t(`Ui! Có gì đó không ổn (${errorCode}).`)}</p>
      <p>{t('Bạn thử lại lần nữa nha!')}</p>
      {errorCode !== 404 && (
        <button className="text-primary large:text-xl py-2 mt-4" onClick={handleRetry} type="button">
          {t('Thử lại')}
        </button>
      )}
    </div>
  )
}

export default ErrorScreen
