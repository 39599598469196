import { memo } from 'react'

const Cross = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.23291 1.45248L1.39968 0.285713L7.99993 6.88596L14.6002 0.285713L15.767 1.45248L9.1667 8.05273L15.5565 14.4425L14.3897 15.6093L7.99993 9.2195L1.61014 15.6093L0.443366 14.4425L6.83316 8.05273L0.23291 1.45248Z"
        fill="white"
      />
    </svg>
  )
}

export default memo(Cross)
