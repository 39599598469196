import { isWebBridgeSupported } from '@shopee/web-bridge-sdk'
import clsx from 'clsx'
import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { IAxiosBaseQueryError } from 'shared/axios-settings'
import { parseTextWithItemName, showToast, simpleHash } from 'shared/utils'
import { isPC, micrositeReminder, showWebBridgeToast, webBridgeReminder } from 'shared/webBridge'
import { RootState, useAppDispatch } from 'store'
import { useAddToCartMutation } from 'store/api.slice'
import { addReminder } from 'store/app.slice'

interface RemindButtonProps {
  remindType: 'event' | 'buy'
  itemName: string
  beginTime: number
  endTime: number
  reminderTitle: string
  reminderContent: string
  redirectPath: string
  reminderId: number
  addedToCart?: boolean
}

//TODO: Should seperate the two type of Remind Button into 2 components
const RemindButton = ({
  remindType,
  itemName,
  reminderId,
  beginTime,
  endTime,
  reminderTitle,
  reminderContent,
  redirectPath,
  addedToCart,
}: RemindButtonProps) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const reminderList = useSelector((state: RootState) => state.app.reminderList)
  const [addToCart] = useAddToCartMutation()

  let remindText: string
  let remindedText: string

  switch (remindType) {
    case 'buy':
      remindText = t('Nhắc mua')
      remindedText = t('Đã bật nhắc mua')
      break
    case 'event':
      remindText = t('Nhắc nhở tôi')
      remindedText = t('Đã tạo nhắc nhở')
      break
  }

  const reminderData = {
    reminderId,
    startTime: Math.round(beginTime / 1000),
    endTime: Math.round(endTime / 1000),
    redirectPath,
    title: parseTextWithItemName(reminderTitle, itemName),
    content: parseTextWithItemName(reminderContent, itemName),
  }

  const hashData = {
    remindType,
    startTime: reminderData.startTime,
    reminderId: reminderData.reminderId,
    redirectPath: reminderData.redirectPath,
    title: reminderData.title,
    content: reminderData.content,
  }
  const hash = simpleHash(JSON.stringify(hashData))
  const isReminded = reminderList.some((reminder) => reminder.hash === hash)

  const reminderHandler = async (ev: React.MouseEvent<HTMLElement>) => {
    ev.stopPropagation()

    if (remindType === 'buy') {
      if (isWebBridgeSupported()) {
        let toastMessage = ''
        let iconType: 'success' | 'failure' = 'success'
        if (!addedToCart) {
          const { error } = (await addToCart({ item_uid: reminderId as number })) as Record<
            string,
            IAxiosBaseQueryError
          >
          if (!error) toastMessage = `${t('Sản phẩm đã được thêm vào giỏ hàng của bạn')}. `
        }

        webBridgeReminder(reminderData)
          .then(() => {
            dispatch(addReminder({ beginTime, hash }))
            toastMessage = `${toastMessage}${t(
              `Bạn sẽ nhận được thông báo khi sản phẩm có giá ưu đãi vào lúc {{time}}`,
              {
                time: dayjs(beginTime).format('HH:mm DD/MM/YYYY'),
              },
            )}`
          })
          .catch((err) => {
            if (err instanceof Error) {
              toastMessage = err.message === 'not allowed' ? t('Có lỗi xảy ra') : t('Vui lòng thử lại')
              iconType = 'failure'
            }
          })
          .finally(() => {
            showWebBridgeToast({
              iconType,
              message: toastMessage,
            })
          })
      } else {
        showToast({
          iconType: 'failure',
          message: t('Tính năng này không được hỗ trợ trên thiết bị của bạn. Vui lòng tải ứng dụng Shopee.'),
        })
      }
    } else if (remindType === 'event') {
      await micrositeReminder(reminderData)
      if (!isPC()) dispatch(addReminder({ beginTime: beginTime, hash }))
    }
  }

  return (
    <button
      className={clsx(
        'border border-primary text-primary rounded large:text-title text-normal disabled:text-disabled disabled:border-disabled w-full',
        remindType === 'buy' && 'py-1.5 large:py-2',
        remindType === 'event' && 'py-2 large:py-5.5',
      )}
      type="button"
      disabled={isReminded}
      onClick={reminderHandler}
    >
      {isReminded ? remindedText : remindText}
    </button>
  )
}

export default React.memo(RemindButton)
