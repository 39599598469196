import { memo } from 'react'

const Success = () => {
  return (
    <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 32C27.3366 32 34.5 24.8366 34.5 16C34.5 7.16344 27.3366 0 18.5 0C9.66344 0 2.5 7.16344 2.5 16C2.5 21.6536 5.43229 26.6223 9.85897 29.4683L8.93664 36L15.3249 31.685C16.3511 31.8916 17.4129 32 18.5 32ZM16.8118 19.4103L24.7582 11.4639C25.3768 10.8454 26.3796 10.8454 26.9982 11.4639C27.6168 12.0825 27.6168 13.0854 26.9982 13.7039L18.4717 22.2305C18.3854 22.3168 18.2912 22.3917 18.1904 22.4548L18.0643 22.5338L17.9666 22.6461C17.9421 22.6742 17.9165 22.7017 17.8898 22.7284C17.2712 23.3469 16.2683 23.3469 15.6497 22.7284L10.9639 18.0425C10.3454 17.424 10.3454 16.4211 10.9639 15.8025C11.5825 15.184 12.5854 15.184 13.2039 15.8025L16.8118 19.4103Z"
        fill="white"
      />
    </svg>
  )
}

export default memo(Success)
